<template>
  <Nav />
  <main>
    <div class="content">
      <slot />
    </div>
  </main>
  <Foot />
</template>

<script>
import Nav from '@/components/Navbar'
import Foot from '@/components/Footer'
export default {
  name: 'AppLayoutHome',
  components: {
    Nav,
    Foot
  }
}
</script>
