import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'AppLayoutHome'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/history/:id',
    name: 'History',
    component: () => import('@/views/History.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/receipt',
    name: 'receipt',
    component: () => import('../views/Receipt.vue'),
    meta: {
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/showhistory',
    name: 'Showhistory',
    component: () => import('../views/ShowHistory.vue'),
    meta: {
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/dealer',
    name: 'Dealer',
    component: () => import('../views/Dealer.vue'),
    meta: {
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/dealerstore',
    name: 'DealerStore',
    component: () => import('../views/DealerStore.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/forgetpassword',
    name: 'Forgetpassword',
    component: () => import('../components/forget-password/forgetpassword.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/otp',
    name: 'OTP',
    component: () => import('../components/forget-password/otp.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import('../components/forget-password/reset-password.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/buylottary',
    name: 'BuyLottary',
    component: () => import('../views/BuyLottary.vue'),
    meta: {
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/getotp',
    name: 'getOTP',
    component: () => import('../components/otp-register/getOTP.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/confirmotp',
    name: 'confirmOTP',
    component: () => import('../components/otp-register/confirmOTP.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  },
  {
    path: '/appeal',
    name: 'Appeal',
    component: () => import('../views/Appeal.vue'),
    meta: {
      layout: 'AppLayoutNosideBar'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
