<template>
  <div class="topnavbar">
    <nav>
      <div class="logo">
        <img src="../../public/HongLottary/Logo-1.png" alt="" />
        <div v-show="mobile" class="icon">
          <font-awesome-icon :icon="['fas', 'bars']" @click="toggleMobileNav" :class="{ 'icon-active': mobileNav }" />
        </div>
      </div>
      <div class="nav-links">
        <div class="links-menu">
          <ul v-show="!mobile">
            <router-link to="/">
              <li>
                <!-- <font-awesome-icon :icon="['fas', 'bars']" class="icon" /> -->
                หน้าแรก
              </li>
            </router-link>
            <router-link to="#">
              <li>
                <!-- <font-awesome-icon :icon="['fas', 'bars']" class="icon" /> -->
                ตัวแทนจำหน่าย
              </li>
            </router-link>
            <router-link to="#">
              <li>
                <!-- <font-awesome-icon :icon="['fas', 'bars']" class="icon" /> -->
                บริการของเรา
              </li>
            </router-link>
            <router-link to="#">
              <li>
                <!-- <font-awesome-icon :icon="['fas', 'bars']" class="icon" /> -->
                ข่าวสาร
              </li>
            </router-link>
            <router-link to="/appeal">
              <li>
                <!-- <font-awesome-icon :icon="['fas', 'bars']" class="icon" /> -->
                ร้องเรียน
              </li>
            </router-link>
            <router-link to="#">
              <li>
                <!-- <font-awesome-icon :icon="['fas', 'bars']" class="icon" /> -->
                ข้อมูลบริษัท
              </li>
            </router-link>
          </ul>
        </div>
      </div>
      <div v-if="!currentUser" class="auth-button-collapse">
        <router-link to="/login">Login</router-link>
      </div>
      <div v-if="currentUser" class="auth-button-collapse">
        <div class="dropdown">
          <div class="list-dropdown">
            <div class="detail-user">
              <span>Hong Lottery</span>
              <img src="../../public/HongLottary/Logo-1.png" alt="" />
            </div>
            <button @click="toggleDropdown" class="" :class="{ 'arrow-active': dropdown }">
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <ul v-show="dropdown" :class="{ 'dropdown-active': dropdown }">
              <li class="logout-btn" @click.prevent="profile">Profile</li>
              <li class="logout-btn" @click.prevent="logOut">Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <transition name="mobile-nav">
      <div v-show="mobileNav & mobile" class="dropdown-nav-wrapper">
        <ul class="dropdown-nav">
          <router-link to="/"><li>หน้าแรก</li></router-link>
          <router-link to="#"><li>ตัวแทนจำหน่าย</li></router-link>
          <router-link to="#"><li>บริการของเรา</li></router-link>
          <router-link to="#"><li>ข่าวสาร</li></router-link>
          <router-link to="#"><li>ร้องเรียน</li></router-link>
          <router-link to="#"><li>ข้อมูลบริษัท</li></router-link>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobileNav: null,
      mobile: null,
      windowWidth: null,
      dropdown: null
    }
  },
  created() {
    this.checkScreen()
    window.addEventListener('resize', this.checkScreen)
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    profile() {
      this.$router.push('/profile')
    },
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },

    checkScreen() {
      this.windowWidth = window.innerWidth
      var width = this.windowWidth
      if (width <= 1199) {
        this.mobile = true
        return
      } else {
        this.mobile = false
        return
      }
    },

    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },

    toggleDropdown() {
      this.dropdown = !this.dropdown
    }
  }
}
</script>
