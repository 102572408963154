<template>
  <Nav />
  <slot />
  <Foot />
</template>

<script>
import Nav from '@/components/Navbar'
import Foot from '@/components/Footer'
export default {
  name: 'AppLayoutNosideBar',
  components: {
    Nav,
    Foot
  }
}
</script>

<style scoped></style>
