import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AppLayout from '@/layouts/AppLayout'
import store from './store'
import './index.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faChevronDown, faBars, faAngleDoubleLeft, faUsers, faBuilding, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toaster from '@meforma/vue-toaster'

library.add(faPhone)
library.add(faChevronDown)
library.add(faBars)
library.add(faAngleDoubleLeft)
library.add(faUsers)
library.add(faBuilding)
library.add(faPhoneAlt)

createApp(App).use(router).use(store).use(Toaster).component('font-awesome-icon', FontAwesomeIcon).component('AppLayout', AppLayout).mount('#app')
