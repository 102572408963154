<template>
  <footer>
    <div class="flex justify-center p-6"><p>copyright all rights reserved design by honglottery 2021</p></div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  }
}
</script>
