import axios from 'axios'

const API_URL = process.env.VUE_APP_ROOT_API

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/login', {
        username: user.username,
        password: user.password
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('phone')
  }

  register(user) {
    return axios.post(API_URL + '/register', {
      name: user.name,
      lastname: user.lastname,
      phone: user.phone,
      birthday: user.birthday,
      password: user.password,
      password_confirmation: user.password_confirmation
    })
  }
  forgetpassword(user) {
    return axios.post(API_URL + '/getotp', {
      phone: user.phone,
    })
      .then((response) => {
        if (response.data.otp) {
          localStorage.setItem('phone', JSON.stringify(response.data.otp.phone))
        }
        console.log(response.data.otp.otp)
        return response.data
      })
  }
  confirmotpreset(user) {
    return axios.post(API_URL + '/confirmotpresetpassword', {
      input_1: user.input_1,
      input_2: user.input_2,
      input_3: user.input_3,
      input_4: user.input_4,
      input_5: user.input_5,
      input_6: user.input_6,
      phone: JSON.parse(localStorage.getItem('phone')),
    })
  }
  resetpassword(user) {
    return axios.post(API_URL + '/resetpassword', {
      new_password: user.new_password,
      confirm_new_password: user.confirm_new_password,
      phone: JSON.parse(localStorage.getItem('phone')),
    })
  }
  confirmotpregister(user) {
    return axios.post(API_URL + '/confirmotpregister', {
      input_1: user.input_1,
      input_2: user.input_2,
      input_3: user.input_3,
      input_4: user.input_4,
      input_5: user.input_5,
      input_6: user.input_6,
      phone: JSON.parse(localStorage.getItem('phone')),
    })
  }
  getOTP() {
    return axios.post(API_URL + '/getotp', {
      phone: JSON.parse(localStorage.getItem('phone')),
    })
      .then((response) => {
        if (response) {
          localStorage.setItem('phone', JSON.stringify(response.data.otp.phone))
        }
        console.log(response.data.otp.otp)
        return response.data
      })
  }
}

export default new AuthService()