<template>
  <Nav />
  <div class="wrapper">
    <SideBar />
    <div class="content">
      <div class="content-wrapper">
        <div class="detail-wrapper">
          <slot />
        </div>
      </div>
      <Foot />
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Navbar'
import Foot from '@/components/Footer'
import SideBar from '../components/SideBar.vue'
export default {
  name: 'AppLayoutDefault',
  components: {
    Nav,
    Foot,
    SideBar
  }
}
</script>

<style scoped></style>
