<template>
  <transition name="fade" appear>
    <div class="modal-overlay" @click="showModal" v-if="modal"></div>
  </transition>
  <transition name="slide">
    <div class="modal-edit" v-if="modal">
      <div class="ht-card">
        <div class="ht-card-wrapper">
          <div class="ht-card-header" style="text-align: left !important">
            <h3>เลือกรูปภาพ</h3>
          </div>
          <div class="show-img">
            <form action="">
              <input type="file" class="form-control" name="" id="form-file" @change="onFileChange" />
            </form>
            <div class="show-img-wrapper">
              <div class="display" v-if="image">
                <img class="img" :src="image" />
              </div>
            </div>
          </div>
          <div class="collapse-btn">
            <div class="btn-wrapper">
              <button class="ht-button green width">อัพเดต</button>
            </div>
            <div class="btn-wrapper">
              <button class="ht-button red width" @click="showModal">ยกเลิก</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <aside>
    <!-- <div v-show="mobile" class="nav-sidebar">
      <div class="nav-sidebar-wrapper">
        <div class="icon icon-left">
          <font-awesome-icon :icon="['fas', 'angle-double-left']" @click="toggleMobileNav" :class="{ 'icon-active': mobileNav }" />
        </div>
      </div>
    </div> -->
    <div class="sidebar-wrapper">
      <div class="img-profile">
        <div class="img-profile-wrapper">
          <div class="img_">
            <img src="../../public/HongLottary/Logo-1.png" />
            <div class="edit-img" @click="showModal">
              <p>Edit</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar -->
      <nav class="sidebar">
        <!-- <div class="sidebar-header"></div> -->
        <ul class="list-unstyled components">
          <router-link to="/profile"><li class="active">ประวัติส่วนตัว</li></router-link>
          <router-link to="/receipt"><li>บัญชีรับเงินรางวัล</li></router-link>
          <router-link to="/showhistory"><li>ประวัติการซื้อ</li></router-link>
          <router-link to="/dealer"><li>ตัวแทนของคุณ</li></router-link>
          <router-link to="#"><li>แลกของรางวัล</li></router-link>
          <router-link to="#"><li>ประวัติการแลกของรางวัล</li></router-link>
          <router-link to="#"><li>ประวัติการถูกรางวัล</li></router-link>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      mobileNav: null,
      mobile: null,
      windowWidth: null,
      dropdown: null,
      modal: null,
      image: ''
    }
  },
  created() {
    this.checkScreen()
    window.addEventListener('resize', this.checkScreen)
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth
      var width = this.windowWidth
      if (width <= 1200) {
        this.mobile = true
        return
      } else {
        this.mobile = false
        return
      }
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },
    showModal() {
      this.modal = !this.modal
      this.image = ''
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      var image = new Image()
      var reader = new FileReader()
      var vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
      }
      reader.readAsDataURL(file)
    }
  }
}
</script>
